
import Vue from "vue";
import i18n from "@/i18n";
import { SaveRes } from "@/models/saveres";
import { Snackbar } from "@/models/snackbar";
import router from "@/router";

export default Vue.extend({
  name: "Login",
  data: () => ({
    returnUrl: "",
    email: "",
    forgotPasswordEmail: "",
    password: "",
    isFormvalid: false,
    isForgotPassword: false,
    isForgotPasswordSuccessful: false,
    ForgotPasswordSuccessfulMessage: "",
    isSendingForgotPassword: false,
    isLoading: false,
    lang: [
      { title: "En", value: "en" },
      { title: "Fr", value: "fr" },
      { title: "Es", value: "es" },
    ],
    rules: {
      required: [(v: string) => !!v || i18n.t("rules.required")],
      emailValid: [
        (v: string) => /.+@.+\..+/.test(v) || i18n.t("rules.emailmustbevalid"),
      ],
    },
  }),
  computed: {
    loginResult() {
      return this.$store.getters["auth/getLoginResult"];
    },
    forgotPasswordResult() {
      return this.$store.getters["auth/getForgotPasswordResult"];
    },
    isAuthenticated() {
      return this.$store.getters["auth/getIsAuthenticated"];
    },
    wholeSnack: {
      get() {
        return this.$store.getters["general/getWholeSnack"];
      },
      set(snack: Snackbar) {
        return this.$store.dispatch("general/changeSnackbar", snack);
      },
    },
  },
  mounted() {
    // redirect to home if already logged in
    if (this.isAuthenticated) {
      return router.push("/");
    }

    //Get return url from route parameters or default to '/'
    this.returnUrl =
      this.$route.query.returnUrl == null
        ? "/"
        : this.$route.query.returnUrl.toString();
  },
  methods: {
    goToRegister() {
      router.push("/register");
    },
    validate() {
      this.isFormvalid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (this.isFormvalid) {
        if (this.isForgotPassword) {
          this.isSendingForgotPassword = true;
          let result = this.ForgotPassword(this.forgotPasswordEmail);
          this.getForgotPasswordResult(result).then((e) => {});
        } else {
          this.isLoading = true;
          this.userLogin(this.email, this.password)
            .then((e) => {
              this.isLoading = false;
              let snack = {
                isSnacking: true,
                snackColor: "success",
                snackMessage: this.$t("basic.loginsuccess").toString(),
                snackTimeout: "2000",
              };
              this.wholeSnack = snack;
              router.push(this.returnUrl);
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response.status == 422) {
                let snack = {
                  isSnacking: true,
                  snackColor: "orange",
                  snackMessage:
                    this.$t("basic.loginsuccess").toString() +
                    err.response.statusText,
                  snackTimeout: "2000",
                };
                this.wholeSnack = snack;
              } else {
                let snack = {
                  isSnacking: true,
                  snackColor: "error",
                  snackMessage: this.$t("basic.loginfailed").toString(),
                  snackTimeout: "2000",
                };
                this.wholeSnack = snack;
              }
            });
        }
      }
    },
    backToLogin() {
      this.isForgotPasswordSuccessful = false;
      this.isForgotPassword = false;
    },
    getForgotPasswordResult(result: Promise<any>) {
      return result
        .then((e) => {
          this.forgotPasswordEmail = "";
          this.isSendingForgotPassword = false;
          if (e.status == 200) {
            this.isForgotPasswordSuccessful = true;
            this.ForgotPasswordSuccessfulMessage = e.data.message;
          }
          return e.status as Number;
        })
        .catch((err) => {
          this.isSendingForgotPassword = false;
        });
    },
    reset() {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    },
    async userLogin(email: string, password: string) {
      await this.$store.dispatch("auth/login", {
        email: email,
        password: password,
      });
      return this.loginResult as SaveRes;
    },
    async ForgotPassword(email: string) {
      await this.$store.dispatch("auth/forgotPassword", {
        culture: i18n.locale,
        data:{email: email,}
      });

      return this.forgotPasswordResult;
    },
  },
});
